import React, { Component } from 'react';
// import logo from './logo.svg';
import './App.css';
import ApTwo from './awq1/awq1';
import Layout1 from './layout/layout';
import axios from 'axios';

axios.defaults.withCredentials = true;

class App extends Component {
    render() {
        return (
            <div>
                {/* <p>ffgbgrg</p> */}
                <Layout1></Layout1>
                {/* <div className="App">
                    <header className="App-header">
                        {/ * <img src={logo} className="App-logo" alt="logo" /> * /}
                        <p>
                            OffSoot Prototype
                        </p>
                        <a
                            className="App-link"
                            href="https://reactjs.org"
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                                Learn React
                        </a>

                        <p>rgrtg</p>
                        <ApTwo></ApTwo>
                    </header>
                </div> */}
            </div>
        );
    }
}

export default App;
