import React, { useEffect, useState } from "react";

import {Button} from 'antd';
import { Link} from 'react-router-dom';
// import { BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';

function NavBar () {
	
	const BLprt = 1;

    const [adm, sAdm] = useState(false);
    const [pru, sPru] = useState(false);


    useEffect(() => {
        sAdm(localStorage.getItem("ug_adm"));
        sPru(localStorage.getItem("ug_pru"));
    }, [])
    window.addEventListener('storage_gr', () => {
        BLprt && console.log("Change to local storage!");
        sAdm(localStorage.getItem("ug_adm"));
        sPru(localStorage.getItem("ug_pru"));
        BLprt && console.log(adm);
        BLprt && console.log(pru);
        BLprt && console.log(localStorage.getItem("ug_adm"));
        BLprt && console.log(localStorage.getItem("ug_pru"));
        // ...
    })


    return (
        <>
            <br></br>
            <Link to="/a_home">
                <Button style={{margin:"5px 20px", width:"120px", height:"32px",}}>
                    home
                </Button>
            </Link>
            <br></br>
            <Link to="/a_one">
                <Button style={{margin:"5px 20px", width:"120px", height:"32px",}}>
                    one
                </Button>
            </Link>
            <br></br>
            <Link to="/a_two">
                <Button style={{margin:"5px 20px", width:"120px", height:"32px",}}>
                    two
                </Button>
            </Link>
            <br></br>
            <Link to="/register">
                <Button style={{margin:"5px 20px", width:"120px", height:"32px",}}>
                    rej
                </Button>
            </Link>
            {pru &&
                <>
                    <br></br>
                    <Link to="/register">
                        <Button style={{margin:"5px 20px", width:"120px", height:"32px",}}>
                            rej1111
                        </Button>
                    </Link>
                </>
            }
            {adm &&
                <>
                    <br></br>
                    <Link to="/register">
                        <Button style={{margin:"5px 20px", width:"120px", height:"32px",}}>
                            rej2222
                        </Button>
                    </Link>
                </>
            }
            <br></br>
            <Link to="/dct/wrd_a">
                <Button style={{margin:"5px 20px", width:"120px", height:"32px",}}>
                    wrd_listt
                </Button>
            </Link>
            <br></br>
            <Link to="/trl/wrdW.WA">
                <Button style={{margin:"5px 20px", width:"120px", height:"32px",}}>
                    tr_wrdA_wrdB
                </Button>
            </Link>
            {/* <Link to="/WP_part">
                <Button style={{margin:"5px 20px", width:"120px", height:"32px",}}>
                    WP_part
                </Button>
            </Link>
            <Link to="/Wp_Part_Dem">
                <Button style={{margin:"5px 20px", width:"120px", height:"32px",}}>
                    WP_part_dem
                </Button>
            </Link>
            <Link to="/Cart_Part">
                <Button style={{margin:"5px 20px", width:"120px", height:"32px",}}>
                    Cart.Part
                </Button>
            </Link>
            <Link to="/a_tmp4">
                <Button style={{margin:"5px 20px", width:"120px", height:"32px",}}>
                    a_tmp4
                </Button>
            </Link>
            <Link to="/right_board">
                <Button style={{margin:"5px 20px", width:"120px", height:"32px",}}>
                    rgt_BOARD
                </Button>
            </Link>
            <Link to="/right_stock">
                <Button style={{margin:"5px 20px", width:"120px", height:"32px",}}>
                    rgt_STOCK
                </Button>
            </Link>
            <Link to="/right_user">
                <Button style={{margin:"5px 20px", width:"120px", height:"32px",}}>
                    rgt_USER
                </Button>
            </Link> */}
            {/* <br></br>
            <Button style={{margin:"5px 20px", width:"120px", height:"32px",}}>
                <Link to="/a11">
                    a11 User
                </Link>
            </Button> */}
            {/* <br></br>
            <Button style={{margin:"5px 20px", width:"120px", height:"32px",}}>
                <Link to="/a22">
                    a22 Part
                </Link>
            </Button> */}
            {/* <br></br>
            <Button style={{margin:"5px 20px", width:"120px", height:"32px",}}>
                <Link to="/a33">
                    a33 Modul
                </Link>
            </Button> */}
            {/* <br></br>
            <Button style={{margin:"5px 20px", width:"120px", height:"32px",}}>
                <Link to="/a44">
                    a44 ModulPart
                </Link>
            </Button> */}
            {/* <br></br>
            <Button style={{margin:"5px 20px", width:"120px", height:"32px",}}>
                <Link to="/a55">
                    a55 Supplier
                </Link>
            </Button> */}
        </>
    )
}

export default NavBar;