import React, { Component } from "react"
// import { Redirect } from "react-router-dom"
// import { useNavigate } from 'react-router-dom';
import axiosInstance from "../AxiosApi"
import { Button } from "antd"



export default class Logout1 extends Component {
	
	BLprt = 1;
	
    constructor() {
        super()
        this.state = {
            redirect: false
        }
    }
    handleLogout = async () => {
        this.BLprt && console.log("loin_loout");
        // const x = localStorage.getItem("refresh_token")
        // const y = localStorage.getItem("access_token")
        // this.BLprt && console.log(" logout refresh_token", x)
        // this.BLprt && console.log(" logout access_token", y)
        try {
            const refresh_token = localStorage.getItem("refresh_token")
            // this.BLprt && console.log(refresh_token)
            this.BLprt && console.log("###############_post token")
            this.BLprt && console.log(refresh_token);
            const response = await axiosInstance.post("token/blacklist/", {
                refresh_token: refresh_token
            })
            this.BLprt && console.log("###############_post token__2")
            localStorage.removeItem("access_token")
            localStorage.removeItem("refresh_token")
            localStorage.removeItem("username")
            localStorage.removeItem("password")
            localStorage.removeItem("authTr")
            localStorage.removeItem("ug_adm")
            localStorage.removeItem("ug_pru")
            this.BLprt && console.log("###############_post token_3")
            window.dispatchEvent(new Event("storagee"));
            this.BLprt && console.log("%%%%%%%%%%%%%%%%%%%%");
            window.dispatchEvent(new Event("storage_gr"));
            axiosInstance.defaults.headers["Authorization"] = null
            this.setState({ redirect: true })
            return response
        } catch (e) {
            this.BLprt && console.log(e)
            this.BLprt && console.log("###############_post token__22")
            localStorage.removeItem("access_token")
            localStorage.removeItem("refresh_token")
            localStorage.removeItem("username")
            localStorage.removeItem("password")
            localStorage.removeItem("authTr")
            localStorage.removeItem("ug_adm")
            localStorage.removeItem("ug_pru")
            this.BLprt && console.log("###############_post token_33")
            window.dispatchEvent(new Event("storagee"));
            axiosInstance.defaults.headers["Authorization"] = null
            this.setState({ redirect: true })
        }
    }
    rederRedirect = () => {
        if (this.state.redirect) {
            // return <Redirect to="/login" />
            this.props.navigate('/login');
            // navigate(`/login`, { replace: true }); // <-- redirect function
        }
    }
    render() {
        return (
            // <>
            // <div>
            //     <h3>Do you want to Logout?</h3>
            //     <h3>{localStorage.getItem("username")}</h3>
            //     {this.rederRedirect()}
                <Button style={{"marginLeft":"10px"}} onClick={this.handleLogout}>Logout11</Button>
            // </div>
            // </>
        )
    }
}
