import React, { useState, useEffect } from "react";

import { Layout, Button } from 'antd';
import { BrowserRouter as Router } from "react-router-dom";
import { LeftOutlined, RightOutlined, DoubleLeftOutlined} from '@ant-design/icons';
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Top1 from "./top";
import Center1 from "./center";
import NavBar from "./navig";
import MyRouts from "./router";
import LayCss from "../css_cmp/cssl"


const {Header, Content, Footer, Sider} = Layout;

function Layout1 () {
	
	const BLprt = 1;

    const [wdhR, sWdhR] = useState(30);
    const [btStl, sBtStl] = useState([<LeftOutlined style={{ color: "#006600" }}/>, <DoubleLeftOutlined style={{ color: "#006600" }}/>])

    const side = (hh=2) => {
        if (wdhR !== 230) {
            sBtStl([<LeftOutlined style={{ color: "#006600" }}/>, <DoubleLeftOutlined style={{ color: "#006600" }}/>]);
            sWdhR(230);
            // BLprt && console.log(230)
        } else {
            if (hh==3){
                sWdhR(730);
            } else {
                sWdhR(530);
                // BLprt && console.log(530)
            }
            sBtStl([<RightOutlined style={{ fontSize: 24, fontWeight: "bold", color: "#0000e6" }}/>,<RightOutlined style={{ fontSize: 24, fontWeight: "bold", color: "#0000e6" }}/>]);
        }
    }
    useEffect(() => {
        // BLprt && console.log(wdhR);
    },[wdhR]);

    // const toggleEdit = () => {
    //     BLprt && console.log("!!!!_4");
    //     setEditing(!editing);
    //     form.setFieldsValue({
    //         [dataIndex]: record[dataIndex],
    //     });
    // };

    return (
        <>
            <Layout style={{height: "100vh", width: "100vw"}}>
                <Router>
                    <Header style={{background:"#35679adf", height:"7vh"}}>
                        2@#
                        <Top1/>
                    </Header>
                    <Layout>
                        <Sider width={160} style={{background:"#95373adf", overflow:"none"}}>
                            1@#
                            <NavBar/>
                        </Sider>
                        <Content style={{overflow:"auto", padding:"20px", backgroundColor:"#a5a7a79f"}}>
                            3@#
                            <Center1/>
                            <MyRouts/>
                        </Content>
                        <Sider width={wdhR} style={{background:"#35373adf"}}>
                            {/* <Button shape="circle" icon={ btStl[0] } onClick={side} style={{"zIndex": 2, "borderColor": "#8c1aff", "borderWidth": 3}}/>
                            <Button shape="circle" icon={ btStl[1] } onClick={() => side(3)} style={{"zIndex": 1, "left": "-10px", "borderColor": "#8c1aff", "borderWidth": 3}}/> */}
                            4@#
                            {/* <LayCss/> */}
                        </Sider>
                    </Layout> 
                </Router>
                <Footer style={{height:"6vh", background:"#a5378adf"}}>
                    5@#
                </Footer>
            </Layout>
        </>
    )
}

export default Layout1;