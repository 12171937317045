import React from "react";
import { useState, useEffect, useRef, useContext, newState } from "react";
import { Button, Table, Form, Input, Modal, Select, Space, TreeSelect, Checkbox, CheckboxProps, Affix } from "antd";
import axios from "axios";
import axiosInstance from "../AxiosApi";
import { CheckOutlined, CloseOutlined, PlusCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import "./app_one.css";
// import "antd/dist/antd.css";

const { Option } = Select;

function ApOne () {



    const [rdToRld, sRdToRld] = useState(false);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [ArrNul, sArrNul] = useState(false);
    const [onowBRD2, sOnowBRD2] = useState(false);
    const BLprt = 1;

    const createItem  = async (event) => {
        // event.preventDefault();
        BLprt && console.log("+_1");
        BLprt && console.log(inAo_one, inAo_two, inAo_three, inAo_fr, inAo_sx, inAo_ph_nmbr, inAo_email, "hhh");
        // PrConsl({vrbl1:"+_1", blPR:BLprt});
        // PrConsl({vrbl1:(inAo_one, inAo_two, inAo_three, inAo_fr, inAo_sx, inAo_ph_nmbr, inAo_email, "hhh"), blPR:BLprt});
        try {
            BLprt && console.log("#$#$%#$%##");
            const response = await axiosInstance.post("aapp/app_one/", {
                ao_one: inAo_one,
                ao_two: inAo_two,
                ao_three: inAo_three,
                ao_fr: inAo_fr,
                ao_sx: inAo_sx,
                ao_ph_nmbr: inAo_ph_nmbr,
                ao_email: inAo_email
            });
            BLprt && console.log("+++==+++");   //123123q!  --  password
            sAo_one("");
            sAo_two("");
            sAo_three("");
            sAo_fr("");
            sAo_sx("");
            sAo_ph_nmbr("");
            sAo_email("");
            sInAlert("");
            setModal1Open(false);
        } catch (error) {
            BLprt && console.log("ERRRRRR");
            BLprt && console.log(error);
            BLprt && console.log("__1");
            BLprt && console.log(error.response.data);
            BLprt && console.log("__2");
            const episodes = error.response.data;
            BLprt && console.log(episodes[0]);
            BLprt && console.log(episodes.type);
            BLprt && console.log(error.response.data.type);
            BLprt && console.log("__2_!");
            BLprt && console.log(Object.prototype.toString.call(error.response.data));
            BLprt && console.log(typeof(error.response.data));
            if (error.response.data) {
                for (const [key, value] of Object.entries(error.response.data)) {
                    BLprt && console.log(`${key}: ${value}`);
                    for (const [key1, value1] of Object.entries(value)) {
                        BLprt && console.log(`${key1}: ${value1}`);
                    }
                }
            }
            BLprt && console.log("__2_@");
            var secondKey = Object.keys(error.response.data)[0];
            BLprt && console.log(error.response.data[secondKey]);
            var thirdKey = Object.keys(error.response.data[secondKey])[0];
            BLprt && console.log(error.response.data[secondKey][thirdKey]);
            var fourthdKey = Object.keys(error.response.data[secondKey])[error.response.data[secondKey].length - 1];
            BLprt && console.log(error.response.data[secondKey].length);
            BLprt && console.log(error.response.data[secondKey][fourthdKey]);
            sInAlert(error.response.data[secondKey][fourthdKey]);

            // const result = {status: 'Success', length: 7, data: {…}};
            // episodes.map(function(item, i){
            //     BLprt && console.log(item);
            // })
            BLprt && console.log("__3");
            BLprt && console.log(error.response.status);
            BLprt && console.log("ERRRRRR");
            // throw(error);
        };
    }

    const FloatLabel = props => {
        const [focus, setFocus] = useState(false);
        const { children, label, value } = props;

        const labelClass =
            focus || (value && value.length !== 0) ? "label label-float" : "label";

        return (
            <div
                className="float-label"
                onBlur={() => (setFocus(false), BLprt && console.log("Fb"))}
                onFocus={() => (setFocus(true), BLprt && console.log("Ff"))}
            >
                {children}
                <label className={labelClass}>{label}</label>
            </div>
        );
    };

    const [firstName, setFirstName] = useState("Nikhil.ao");
    const [lastName, setLastName] = useState("Mahirrao");
    const [treeValue, setTreeValue] = useState(["0-0-0"]);
    const [selectValue, setSelectValue] = useState();

    const treeData = [
        {
            title: "Node1",
            value: "0-0",
            key: "0-0",
            children: [
                {
                    title: "Child Node1",
                    value: "0-0-0",
                    key: "0-0-0"
                }
            ]
        },
        {
            title: "Node2",
            value: "0-1",
            key: "0-1",
            children: [
                {
                    title: "Child Node3",
                    value: "0-1-0",
                    key: "0-1-0"
                },
                {
                    title: "Child Node4",
                    value: "0-1-1",
                    key: "0-1-1"
                },
                {
                    title: "Child Node5",
                    value: "0-1-2",
                    key: "0-1-2"
                }
            ]
        }
    ];

    const tProps = {
        treeData,
        value: treeValue,
        onChange: e => setTreeValue(e),
        treeCheckable: true,
        style: {
            width: "100%"
        }
    };

    const [isModalOpen, setIsModalOpen] = useState([false, false]);
    const toggleModal = (idx, target) => {
        setIsModalOpen((p) => {
            p[idx] = target;
            return [...p];
        });
    };

    const {confirm} = Modal;
    const [wpDT, setWpdt] = useState([]);
    const [wpDTNew, setWpdtNew] = useState([]);
    const [wpDsf, setWpdsf] = useState([]);
    const [upList, sUpList] = useState([]);
    const [datadata, setDatadata] = useState([]);
    const [datadaSF, setDatadaSF] = useState([]);
    const [btndisablecount, setBtndisablecount] = useState(true);

    const onWpBrd = () => {
        // const response = await axiosInstance.get("/v1/about/")
        // this.setState({
        //     aboutData: response.data
        // })
        // BLprt && console.log(response.data)
        // return response.data
        BLprt && console.log("+_one_1");
        // axios.get('http://127.0.0.1:8000/api/aapp/app_one/')
        // axiosInstance.get("/aapp/app_one/");

        // // axiosInstance.get("/aapp/app_one/", headers: {'Cache-Control': 'no-cache'})
        axiosInstance.get("/aapp/app_one/"+'?dtqw='+(new Date()).getTime()+'&qId=5')
        // axiosInstance.get("/aapp/app_one/")
        // axiosInstance.get("/aapp/app_one/")
        .then(res => {
            BLprt && console.log("++++ App One");
            BLprt && console.log(res);
            BLprt && console.log(res.data);
            // BLprt && console.log(res.data[0]);
            // BLprt && console.log(res.data[1]);
            // BLprt && console.log(res.data[2]);
            // BLprt && console.log(typeof(res.data[0]));
            // BLprt && console.log(typeof(res.data[2]));
            BLprt && console.log(res.data.results);
            BLprt && console.log("====================");
            BLprt && console.log("====================");
            // BLprt && console.log(res.data[0][0])
            // BLprt && console.log(res.data[0][0].ao_one)
            // BLprt && console.log("____________________");
            // BLprt && console.log(res.data[2][0])
            // BLprt && console.log(res.data[2][0].pk)
            // BLprt && console.log(res.data[2][0].fields)
            // BLprt && console.log(res.data[2][0].fields.ao_one)
            // BLprt && console.log("====================");
            // BLprt && console.log("====================");
            setWpdt(res.data[0]);
            sUpList(res.data[2]);
            // onWpBrd2();
        })
        .catch(err => {BLprt && console.log("err");
                BLprt && console.log(err)});

    };

    const onWpBrd2 = () => {
        // axiosInstance.get("/aapp/app_oneSF/"+'?dtqw='+(new Date()).getTime()+'&qId=5')
        // // axiosInstance.get("/aapp/app_one/")
        // .then(res => {
        //     BLprt && console.log("++++ App One SF");
        //     setWpdsf(res.data[0]);
        // })
        // // .catch(err => BLprt && console.log(err));
        // .catch(err => BLprt && console.log("errNn"));



        BLprt && console.log("+_one_1SFFFSFSFSF.SF");
        var vvbv = ["ffVB","VBh"]
        axiosInstance.get("/aapp/app_oneSF/"+'?S3='+3+'&S6='+JSON.stringify(filteredInfo)+'&S9='+3333333+'&F6='+5555+'&F9='+5555555+'&ssF9='+sortedInfo_n_col9.order+'&ddF9='+sort_mul_col9+'&ggF9='+vvbv+'&ooF9='+JSON.stringify([sortedInfo_n_col9.order, sort_mul_col9])+'&qqS3='+JSON.stringify([sortedInfo_n_col3.order, sort_mul_col3])+'&qqS6='+JSON.stringify([sortedInfo_n_col6.order, sort_mul_col6])+'&qqS9='+JSON.stringify([sortedInfo_n_col9.order, sort_mul_col9])+'&qqF6='+JSON.stringify(filteredInfo.Parm6)+'&qqF9='+JSON.stringify(filteredInfo.Parm9))
        .then(res => {
            BLprt && console.log("SSSSSSSSSSSSSSSSSSSS")
            BLprt && console.log(res.data)
            BLprt && console.log(res.data[0])
            BLprt && console.log(res.data[0][0])
            // BLprt && console.log(res.data[0][0])
            // BLprt && console.log(res.data[0][0].ao_one)
            // BLprt && console.log("____________________");
            BLprt && console.log(res.data[2])
            // BLprt && console.log(res.data[2][0])
            // BLprt && console.log(res.data[2][0].pk)
            // BLprt && console.log(res.data[2][0].fields)
            // BLprt && console.log(res.data[2][0].fields.ao_one)
            // BLprt && console.log("====================");
            // BLprt && console.log("====================");
            setWpdtNew(res.data[0]);
            setWpdsf(res.data[0]);
        })
        .catch(err => BLprt && console.log("errNew"));
    };

    useEffect(() => {
        onWpBrd();
    }, []);

    useEffect(() => {
        updtDtdt();
    },[wpDT]);

    useEffect(() => {
        updtDtdtSF();
    },[wpDsf]);

    useEffect(() => {
        var tmp2 = 0;
        datadata.forEach(eachNDD2 => {
            tmp2 = tmp2 + (eachNDD2.Parm3*1);
        });
        sGenTotal(tmp2);
        sTotalLast(tmp2);
    },[datadata]);

    const onChange = (e) => {
        // BLprt && console.log('checked = ', e.target.checked);
        sAo_sx(e.target.checked);
    };

    const [disabled, setDisabled] = useState(false);
    const [inAo_one, sAo_one] = useState("");
    const [inAo_two, sAo_two] = useState("");
    const [inAo_three, sAo_three] = useState("");
    const [inAo_fr, sAo_fr] = useState("");
    const [inAo_sx, sAo_sx] = useState(false);
    const [inAo_ph_nmbr, sAo_ph_nmbr] = useState("");
    const [inAo_email, sAo_email] = useState("");
    const [inAlert, sInAlert] = useState("");
    const label = 'Parm7, PRIO';
    // const label = `${checked ? 'Checked' : 'Unchecked'}-${disabled ? 'Disabled' : 'Enabled'}`;
    // const inputRefMod = useRef("");
    // const inputRefMod2 = useRef("");
    // const inpArray = [inputRefMod, inputRefMod2]
    let inpArray = [inAo_one, inAo_two, inAo_three, inAo_fr, inAo_ph_nmbr, inAo_email];

    const formRef = React.useRef(null);

    function handleSubmit(event) {
        event.preventDefault();
        sInAlert("");
        if (inpArray.includes("")) {
            BLprt && console.log("Input is empty");
            sInAlert("Fill all fields");
        } else {
            BLprt && console.log("Input is not empty");
            createItem();
        }
        // if (inputRefMod2.current.value.trim() === "") {
        //     BLprt && console.log("Input is empty");
        // } else {
        //     BLprt && console.log("Input is not empty");
        // }
    };

    const updtDtdt = () => {
        BLprt && console.log("ddatata");
        BLprt && console.log(wpDT);
        BLprt && console.log(">>>_____ddatata____>>>");
        setDatadata(wpDT.map((object, ik) => {return {...object, key: object.id, Parm1: object.id, Parm2: object.ao_one,
            // Parm2_2: (object.ao_owner_data.age + ". LL"), Parm2_4444: (object.ao_owner_age + ". LL"),
            // Parm2_2: (object.ao_owner_data),
            // Parm2_2: (object.ao_owner_data.id),
            Parm2_2: (object.ao_owner + " .  . " + object.ao_qty + " ... " + object.ao_fr_2 + " .-. " + (object.ao_owner_data.id || "lllo")),
        Parm3: object.ao_two, Parm3_1: object.ao_two, Parm3_2: object.ao_qty, Parm3_diff:false, Parm4: object.ao_dtrt, Parm5: object.ao_three, Parm6: object.ao_fr, Parm6_1: object.ao_fr_1, Parm6_2: object.ao_fr_2, Parm6_diff:false, Parm7: object.ao_sx,
            // Parm8: object.phone_regex,
        Parm9: object.ao_ph_nmbr, Parm10: object.ao_email, diffQty:false, diffQtyMn:false, diffQtyMx:false}}));
    };

    const updtDtdtSF = () => {
        BLprt && console.log("ddatataSF");
        BLprt && console.log(wpDsf);
        setDatadaSF(wpDsf.map((object, ikk) => {return {...object, key: object.id, Parm1: object.id, Parm2: object.ao_one, Parm2_2: (object.ao_owner), Parm3: object.ao_two, Parm3_1: object.ao_two, Parm3_2: object.ao_qty, Parm3_diff:false, Parm4: object.ao_dtrt, Parm5: object.ao_three, Parm6: object.ao_fr, Parm6_1: object.ao_fr_1, Parm6_2: object.ao_fr_2, Parm6_diff:false, Parm7: object.ao_sx,
            Parm8: object.phone_regex,
        Parm9: object.ao_ph_nmbr, Parm10: object.ao_email, diffQty:false, diffQtyMn:false, diffQtyMx:false}}));
    };

    useEffect (() => {
        BLprt && console.log("ddatataSF'''");
        BLprt && console.log("wpDsf'''");
        BLprt && console.log(datadaSF);
    }, [datadaSF])

    const EditableContext = React.createContext(null);

    const EditableCell = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        handleSave,
        ...restProps
    }) => {
        const [editing, setEditing] = useState(false);
        const inputRef = useRef(null);
        const form = useContext(EditableContext);
        useEffect(() => {
            if (editing) {
                inputRef.current.focus();
            }
        }, [editing]);
        const toggleEdit = () => {
            BLprt && console.log("!!!!_4");
            setEditing(!editing);
            form.setFieldsValue({
                [dataIndex]: record[dataIndex],
            });
        };
        const save = async () => {
            BLprt && console.log("!!!!_2");
            try {
                const values = await form.validateFields();
                toggleEdit();
                // BLprt && console.log(values);
                // BLprt && console.log(values.PrtQty);
                // BLprt && console.log(values.hasOwnProperty('PrtQty'))
                var myInteger = values.PrtQty;
                if (values.hasOwnProperty('PrtQty')) {
                    if( parseInt( myInteger ) === myInteger && myInteger > 0 ) {
                        BLprt && console.log("number");
                        handleSave({
                            ...record,
                            ...values,
                        });
                    }else{
                        BLprt && console.log("NOT number");
                    }
                } else {
                    handleSave({
                        ...record,
                        ...values,
                    });
                }
                BLprt && console.log("___>");
            } catch (errInfo) {
                BLprt && console.log('Save failed:', errInfo);
            }
        };
        let childNode = children;
        if (editable) {
            childNode = editing ? (
                <span>
                    <Form.Item
                        style={{
                            margin: 0,
                        }}
                        name={dataIndex}
                        rules={[
                            {
                                required: true,
                                message: `${title} is required.`,
                            },
                        ]}
                    >
                        <Input style={{width:'80%', color:'green'}} ref={inputRef} onPressEnter={save} onBlur={save} />
                    </Form.Item>
                    {/* <span>fdf<Button style={{margin:'10px'}}>A</Button><Button style={{margin:'20px'}}>B</Button>r</span> */}
                </span>
            ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
            );
        }
        return <td {...restProps}>{childNode}</td>;
    };


    const EditableRow = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
            </Form>
        );
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const handleSave = (row) => {
        // BLprt && console.log("<==");
        BLprt && console.log("handleSave");
        // BLprt && console.log(row);
        // BLprt && console.log(row.key);
        const newData = [...datadata];
        // BLprt && console.log(newData);
        const index = newData.findIndex((item) => row.key === item.key);
        const itemm = newData[index];
        // BLprt && console.log(index);
        // BLprt && console.log(row.key);
        // BLprt && console.log(row.id);
        // BLprt && console.log(row.Parm6);
        // BLprt && console.log(row.Parm6_1);
        // BLprt && console.log(row.Parm6_2);
        // BLprt && console.log(row.Parm6_diff);
        // BLprt && console.log(row.Parm3_diff);
        row.Parm6 === row.Parm6_1 ? row.Parm6_diff = false : row.Parm6_diff = true;
        row.Parm3 === row.Parm3_1 ? row.Parm3_diff = false : row.Parm3_diff = true;
        // BLprt && console.log(row.Parm6_diff);
        // BLprt && console.log(row.Parm3_diff);
        // row.qty === row.PrtQty2 ? row.diffQty = false : row.diffQty = true;
        // row.qty_min === row.PrtQtyMn2 ? row.diffQtyMn = false : row.diffQtyMn = true;
        // row.qty_max === row.PrtQtyMx2 ? row.diffQtyMx = false : row.diffQtyMx = true;
        // row.diff = true;
        // BLprt && console.log(itemm);
        newData.splice(index, 1, {
            ...itemm,
            ...row,
        });
        // BLprt && console.log(newData)
        // BLprt && console.log("==>");
        setDatadata(newData);
    };

    const cancelChanges = (row) => {
        // BLprt && console.log("<==");
        BLprt && console.log("cancelChanges");
        // BLprt && console.log(row);
        // BLprt && console.log(row.Parm3);
        // BLprt && console.log(row.Parm6);
        const newData = [...datadata];
        const index = newData.findIndex((item) => row.key === item.key);
        const itemm = newData[index];
        row.Parm3 = row.Parm3_1;
        row.Parm6 = row.Parm6_1;
        row.Parm3_diff = false;
        row.Parm6_diff = false;
        newData.splice(index, 1, {
            ...itemm,
            ...row,
        });
        setDatadata(newData);
        // BLprt && console.log("==>");
    };

    const cnclAll = () => {
        BLprt && console.log("cnclAll");
        datadata.forEach(eachprt => {
            // var dfbln = (eachprt.PrtPart_name !== eachprt.PrtPart_name2); тимчасово, при потребі)
            var dfP3 = (eachprt.Parm3 !== eachprt.Parm3_1);
            var dfP6 = (eachprt.Parm6 !== eachprt.Parm6_1);
            var dfbln1 = false;
            (dfP3 === true || dfP6 === true) ? dfbln1 = true : dfbln1 = false;
            if (dfbln1) {
                const newData = [...datadata];
                const index = newData.findIndex((item) => eachprt.key === item.key);
                const itemm = newData[index];
                eachprt.Parm3 = eachprt.Parm3_1;
                eachprt.Parm6 = eachprt.Parm6_1;
                eachprt.Parm3_diff = false;
                eachprt.Parm6_diff = false;
                newData.splice(index, 1, {
                    ...itemm,
                    ...eachprt,
                });
                setDatadata(newData);
            };
        });
    }

    const saveToDtbs = () => {
        BLprt && console.log("SAVE");
        var dfMbln = false;
        datadata.forEach(eachprt => {
            // var dfbln = (eachprt.PrtPart_name !== eachprt.PrtPart_name2); тимчасово, при потребі)
            var dfP3 = (eachprt.Parm3 !== eachprt.Parm3_1);
            var dfP6 = (eachprt.Parm6 !== eachprt.Parm6_1);
            var dfbln1 = false;
            (dfP3 === true || dfP6 === true) ? dfbln1 = true : dfbln1 = false;
            if (dfbln1) {
                dfMbln = true;
        //         BLprt && console.log(eachprt);
        //         BLprt && console.log(eachprt.PrtId);
        //         BLprt && console.log(dfbln1);
                var dfobj = ({
                    id:eachprt.key,
                    ao_one:eachprt.Parm2,
                    ao_two:eachprt.Parm3,
                    ao_three:eachprt.Parm5,
                    ao_fr:eachprt.Parm6,
                    some_field:'jjjj',
                    // ao_sx:eachprt.Parm7,
                    // ao_ph_nmbr:eachprt.Parm9,
                    // ao_email:eachprt.Parm10,
                });
                BLprt && console.log('_________________________dfobj');
                BLprt && console.log(dfobj);
                // axios.put(`http://127.0.0.1:7801/api/board/wpsecpart/${eachprt.Parm1}/`, dfobj)
                // axios.put(`http://127.0.0.1:8000/api/aapp/app_one/${eachprt.Parm1}/`, dfobj)
                // axios.put(`http://localhost:8000/api/aapp/app_one/${eachprt.Parm1}/`, dfobj)
                // axiosInstance.put(`/aapp/app_one/${eachprt.Parm1}/`, dfobj)
                axiosInstance.put(`/aapp/app_one/${eachprt.Parm1}/`+'?dtputqw='+(new Date()).getTime(), dfobj)
                    .then(res => BLprt && console.log(res.data))
                    .catch(err => BLprt && console.log(err))
            };
        });
        BLprt && console.log("==_______ &  " + new Date().toLocaleDateString());
        setTimeout(function(){
            BLprt && console.log("Pause");
            // ііі  розкоментувати, бо итмчасове
            // if (dfMbln){ {onWpBrd()}};
        }, 2100);
        BLprt && console.log("++_______ &  " + new Date().toLocaleDateString())
    };

    const [sortedInfo_m, setSortedInfo_m] = useState([]);
    const [sortedInfo_n_col3, setSortedInfo_n_col3] = useState({});
    const [sortedInfo_n_col6, setSortedInfo_n_col6] = useState({});
    const [sortedInfo_n_col9, setSortedInfo_n_col9] = useState({});
    const [sort_mul_col3, setSort_mul_col3] = useState({});
    const [sort_mul_col6, setSort_mul_col6] = useState({});
    const [sort_mul_col9, setSort_mul_col9] = useState({});

    useEffect(() =>{
        BLprt && console.log("sortedInfo_n_col9  __--__", sortedInfo_n_col9)
    }, [sortedInfo_n_col9])

    useEffect(() => {
        let a1 = 0;
        var el1 = [];
        var el_id;
        var newR;
        if (Array.isArray(sortedInfo_m) == false){
            if (Object.values(sortedInfo_m).length == 4){
        //         setSortedInfo_m_col1(sortedInfo_m);
        //         setSortedInfo_m_col2([]);
                a1 = 1;
        //         // setSortedInfo_m_new(sortedInfo_m.keys(data).reduce)
        //         // setSortedInfo_m_new(Object.entries(sortedInfo_m).map((item, index)=>{}))
                newR = Object.values(sortedInfo_m);
                newR = [sortedInfo_m];
            };
        } else {
        //     setSortedInfo_m_col1(sortedInfo_m[0]);
        //     setSortedInfo_m_col2(sortedInfo_m[1]);
        //     const found_1 = sortedInfo_m.find(element => {
        //         return element.field === 'cart_order';
        //     })
        //     BLprt && console.log("--____  " + found_1);
            a1 = 2;
        //     sortedInfo_m.some(function(entry, i) {
        //         if (entry.field == 'cart_order'){
        //             el_id = i;
        //             el1 = entry;
        //         }
        //     });
            newR = sortedInfo_m;
        //     BLprt && console.log("--____ = " + el1);
        //     BLprt && console.log("--____ = " + el1.field);
        //     BLprt && console.log("--____ = " + el_id);
        };
        if (newR){
            BLprt && console.log(newR.length);
        }
        // BLprt && console.log(newR);
        BLprt && console.log("_a1__" + a1);
        BLprt && console.log(" newR  . ", newR);
        // // const findColName = sortedInfo_m.filter(filtrData);
        // // BLprt && console.log(findColName);
        BLprt && console.log("sortedInfo_m  ", sortedInfo_m);
        // newR = Object.values(sortedInfo_m);
        setSortedInfo_n_col3([]);
        setSortedInfo_n_col6([]);
        setSortedInfo_n_col9([]);
        setSort_mul_col3(0);
        setSort_mul_col6(0);
        setSort_mul_col9(0);
        BLprt && console.log(" newR  . ", newR);
        //  ТУТ ТРЕБА ПЕРЕРОБИТИ БО НЕПРАВИЛЬНИЙ ПОРЯДОК ПРИСВОЄННЯ ""Sort_mul_col*"", але то за бажанням ))))

        // col9 sort

        // Various p1
        //     Object { column: {…}, order: "ascend", field: "Parm9", columnKey: "Parm9" }
        //     column: Object { dataIndex: "Parm9", key: "Parm9", editable: true, … }
        //     columnKey: "Parm9"
        //     field: "Parm9"
        //     order: "ascend"
        //     <prototype>: Object { … }
            // sort_mul_col3  0
            // sort_mul_col6  0
            // sort_mul_col9  -1


        // col3 sort

        // Various p1
        //     Array [ {…}, {…} ]
        //     0: Object { order: "ascend", field: "Parm9", columnKey: "Parm9", … }
        //     1: Object { order: "ascend", field: "Parm3", columnKey: "Parm3", … }
        //     length: 2
        //     <prototype>: Array []
            // sort_mul_col3  -2
            // sort_mul_col6  0
            // sort_mul_col9  -1


        // col9 sort

        // Various p1
        //     Array [ {…}, {…} ]
        //     0: Object { order: "ascend", field: "Parm3", columnKey: "Parm3", … }
        //     1: Object { order: "descend", field: "Parm9", columnKey: "Parm9", … }
        //     length: 2
        //     <prototype>: Array []
            // sort_mul_col3  -1
            // sort_mul_col6  0
            // sort_mul_col9  -2

        //  ТУТ ТРЕБА ПЕРЕРОБИТИ БО НЕПРАВИЛЬНИЙ ПОРЯДОК ПРИСВОЄННЯ ""Sort_mul_col9"" СТАЄ, але то за бажанням ))))
        if (newR){
            newR.some(function(entry, iw1) {
                BLprt && console.log("entry__", entry);
                BLprt && console.log("iw1__", iw1);
                if (entry.field == 'Parm3'){
                    el_id = iw1;
                    el1 = entry;
                    setSort_mul_col3(-(iw1+1));
                    setSortedInfo_n_col3(entry);
                };
                if (entry.field == 'Parm6'){
                    el_id = iw1;
                    el1 = entry;
                    setSort_mul_col6(-(iw1+1));
                    setSortedInfo_n_col6(entry);
                };
                if (entry.field == 'Parm9'){
                    el_id = iw1;
                    el1 = entry;
                    setSort_mul_col9(-(iw1+1));
                    if (entry.order===undefined){
                        BLprt && console.log("_+_+_+__+_+_+__+_+");
                    };
                    setSortedInfo_n_col9(entry);
                    BLprt && console.log("++++++======__setSortedInfo_n_col9(entry);",entry);
                    BLprt && console.log("++++++======");
                };
            });
            BLprt && console.log("sort_mul_col3 ", sort_mul_col3);
            BLprt && console.log("sort_mul_col6 ", sort_mul_col6);
            BLprt && console.log("sort_mul_col9 ", sort_mul_col9);
            BLprt && console.log("sortedInfo_n_col3 ", sortedInfo_n_col3);
            BLprt && console.log("sortedInfo_n_col6 ", sortedInfo_n_col6);
            BLprt && console.log("sortedInfo_n_col9 ", sortedInfo_n_col9);
        };
        BLprt && console.log('>>>>>>>cart_order');
        // 'cart_order'
    }, [sortedInfo_m]);

    useEffect (() => {
        // function getIndex(email) {
            var dfbln = datadata.findIndex(obj => obj.Parm3_diff === true);
            var dfblnQty = datadata.findIndex(obj => obj.Parm6_diff === true);
            // var dfblnQtyMn = datadata.findIndex(obj => obj.diffQtyMn === true);
            // var dfblnQtyMx = datadata.findIndex(obj => obj.diffQtyMx === true);
            var stringQ = "><<<" + dfbln + ", " + dfblnQty + ">>><";
            // PrConsl({ vrbl1: "false" });
            // PrConsl({ blPR: false });
            // PrConsl({vrbl1:"ttt", blPR: false});
            BLprt && console.log(stringQ);
            (dfbln > -1 || dfblnQty > -1) ? setBtndisablecount(false) : setBtndisablecount(true) ;
        // }
    }, [datadata]);
 
    const columns = [
        {
            title: 'P1',
            dataIndex: 'Parm1',
            key: 'Parm1',
            fixed: 'left',
            width: '35px',
        },
        // {
        //     title: 'WP Name',
        //     dataIndex: 'wp_name',
        //     key: 'wp_name',
        //     fixed: 'left',
        //     // width: '40px',
        //     // editable: true,
        // },
        {
            title: 'Parm 2',
            dataIndex: 'Parm2',
            key: 'Parm2',
            // fixed: 'left',
            // editable: true,
        },
        {
            title: 'Parm 2_2',
            dataIndex: 'Parm2_2',
            key: ('Parm2_2'+'Parm2'+'Parm2'),
            // fixed: 'left',
            // editable: true,
        },
        // {
        //     title: 'part',
        //     dataIndex: 'wp_part',
        //     key: 'wp_part',
        //     // width: '30px',
        // },
        {
            // title: 'Parm 3',
            // title: () => {
            //     var total = 0;
            //     for (var i = 0; i < datadata.length; i++) {
            //         total += datadata[i].Parm3;
            //     }
            //     return (
            //         <div>
            //             Amount <br /> {total}
            //         </div>
            //     );
            // },
            // title: (text, record) => {
            //     var total = 0;
            //     for (var i = 0; i < record.length; i++) {
            //         total += record[i].Parm3;
            //     }
            //     return (
            //         <div>
            //             Amount <br /> {total}
            //         </div>
            //     );
            // },
            title: () => {
                return (
                    <div>
                        Amount {RTotal} gf {subTotal} jj <br />
                    </div>
                );
            },

            sorter: {
                compare: (a, b) => a.Parm3 - b.Parm3,
                // multiple: 3,
                multiple: sort_mul_col3,
            },
            // sortOrder: sortedInfo.columnKey === 'Parm3' ? sortedInfo.order : null,
            sortOrder: sortedInfo_n_col3.columnKey === 'Parm3' ? sortedInfo_n_col3.order : null,

            // title: () => {
            //     var total = 110;
            //     // for (var i = 0; i < data.length; i++) {
            //     //     total += data[i].amount;
            //     // }
            //     return (
            //         <div>
            //             Amount <br /> {total}
            //         </div>
            //     );
            // },
            dataIndex: 'Parm3',
            key: 'Parm3',
            width: '155px',
            editable: true,
            render: (text, record) => {
                // var total = 0;
                // BLprt && console.log("FFFF");
                // BLprt && console.log(record);
                // BLprt && console.log(record.Parm3);
                // BLprt && console.log(RTotal);
                // // for (var i = 0; i < record.length; i++) {
                // //     total += record[i].Parm3;
                // //     BLprt && console.log("____",total);
                // // };
                // // sRTotal(record.Parm3 + RTotal);
                return (
                    <span>
                        {record.Parm3 % 1 === 0 ?
                            (record.Parm3 === 0 ?
                                ""
                            :
                                record.Parm3)
                        :
                            record.Parm3.toFixed(2)}
                        <br></br>
                        {record.Parm3_1}
                        <br></br>-
                        {record.Parm3_2}
                        {record.Parm3_diff ?
                            <Button danger type='primary' shape='circle' style={{background:"rgb(231,111,111)", marginLeft:"5px"}} size="small" icon={<CloseOutlined />}  onClick={() => cancelChanges(record)}/>
                        :
                            ""}
                    </span>
                );
            },
            // width: '140px',
            // render: (text, record) => (
            //     <span>
            //         {record.wp_part}
            //         {record.diff ?
            //             <Button danger type='primary' shape='circle' style={{background:"rgb(231,111,111)", marginLeft:"5px"}} size="small" icon={<CloseOutlined />}  onClick={() => cancelChanges(record)}/>
            //         :
            //             ""}

            //     </span>
            // ),
        },
        {
            title: 'Parm 4',
            dataIndex: 'Parm4',
            key: 'Parm4',
            alignContent: 'center',
        },
        {
            title: 'Parm 5',
            dataIndex: 'Parm5',
            key: 'Parm5',
            width: '90px',
        },
        ///////////////////////

        {
            title: 'tmp',
            dataIndex: 'Parm6_1',
            key: 'Parm6_1',
            render: (text, record) => (
                <span>
                    {record.Parm6}<br></br>
                    {record.Parm6_1}<br></br>
                    {record.Parm6_2}
                </span>
            ),
        },
        {
            title: 'Parm 6',
            dataIndex: 'Parm6',
            filters: [
                {
                    text: 'Pryvit',
                    value: 'Pryvit',
                },
                {
                    text: 'ao58y32',
                    value: 'ao58y32',
                },
                {
                    text: 'fv',
                    value: 'fv',
                },
                {
                    text: 'kv',
                    value: 'kv',
                },
                {
                    text: 'q',
                    value: 'q',
                },
            ],

            filteredValue: filteredInfo.Parm6 || null,
            onFilter: (value, record) => record.Parm6.includes(value),
            sorter: {
                compare: (a, b) => a.Parm6.localeCompare(b.Parm6),
                // compare: (a, b) => a.Parm6.length - b.Parm6.length,
                // compare: (a, b) => a.Parm6 - b.Parm6,
                multiple: sort_mul_col6,
                // multiple: 2,
            },
            // sortOrder: sortedInfo.columnKey === 'Parm6' ? sortedInfo.order : null,
            sortOrder: sortedInfo_n_col6.columnKey === 'Parm6' ? sortedInfo_n_col6.order : null,

            // onFilter: (value, record) => record.Parm6.includes(value),
            // // filterSearch: true,
            key: 'Parm6',
            editable: true,
            // sorter: {
            //     compare: (a, b) => a.Parm6 - b.Parm6,
            //     multiple: 1,
            // },
            render: (text, record) => (
                <span>
                    {record.Parm6} - <br></br>
                    {record.Parm6_1} - <br></br>
                    {record.Parm6_2}
                    {record.Parm6_diff ?
                        <Button danger type='primary' shape='circle' style={{background:"rgb(231,111,111)", marginLeft:"5px"}} size="small" icon={<CloseOutlined />}  onClick={() => cancelChanges(record)}/>
                    :
                        ""}
                </span>
            ),
        },
        {
            title: 'Parm 7',
            dataIndex: 'Parm7',
            key: 'Parm7',
            render: (text, record) => (
                <span>
                    {record.Parm7 ?
                        <span>PRIO</span>
                    :
                        ""}

                </span>
            ),
        },
        // {
        //     title: 'Parm 8',
        //     dataIndex: 'Parm8',
        //     key: 'Parm8',
        // },
        // {
        //     title: 'Qty_min',
        //     dataIndex: 'qty_min',
        //     key: 'qty_min',
        //     editable: true,
        //     sorter: {
        //         compare: (a, b) => a.qty_min - b.qty_min,
        //         multiple: 1,
        //     },
        //     render: (text, record) => (
        //         <span>
        //             {record.qty_min}
        //             {record.diffQtyMn ?
        //                 <Button danger type='primary' shape='circle' style={{background:"rgb(231,111,111)", marginLeft:"5px"}} size="small" icon={<CloseOutlined />}  onClick={() => cancelChanges(record)}/>
        //             :
        //                 ""}

        //         </span>
        //     ),
        // },
        {
            // title: 'Parm 9',
            title: <div><span>rgrg</span></div>,
            filters: [
              {
                text: '2',
                value: '2',
              },
              {
                text: '1',
                value: '1',
              },
              {
                text: '9',
                value: '9',
              },
              {
                text: '7',
                value: '7',
              },
              {
                text: '6',
                value: '6',
              },
            ],

            filteredValue: filteredInfo.Parm9 || null,
            onFilter: (value, record) => record.Parm9.includes(value),

            // onFilter: (value, record) => record.Parm9.includes(value),
            dataIndex: 'Parm9',
            key: 'Parm9',
            editable: true,
            // sorter: (a, b) => a.Parm9 - b.Parm9,
            // sorter: (a, b) => a.Parm9.localeCompare(b.Parm9),
            sorter: {
                compare: (a, b) => a.Parm9.localeCompare(b.Parm9),
                multiple: sort_mul_col9,
                // multiple: 1,
            },
            // sortOrder: sortedInfo.columnKey === 'Parm9' ? sortedInfo.order : null,
            sortOrder: sortedInfo_n_col9.columnKey === 'Parm9' ? sortedInfo_n_col9.order : null,

            // sorter: (a, b) => a.Parm6.length - b.Parm6.length,
            // render: (text, record) => (
            //     <span>
            //         {record.qty_max}
            //         {record.diffQtyMx ?
            //             <Button danger type='primary' shape='circle' style={{background:"rgb(231,111,111)", marginLeft:"5px"}} size="small" icon={<CloseOutlined />}  onClick={() => cancelChanges(record)}/>
            //         :
            //             ""}

            //     </span>
            // ),
        },
        {
            title: 'Parm 10',
            dataIndex: 'Parm10',
            key: 'Parm10',
        },
    ]

    const columnsS = columns.map((col) => {
        if(!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        }
    });


    useEffect(() => {
        var rr=1;
        BLprt && console.log(".+++");
        // BLprt && console.log(wpDT);
        // // BLprt && console.log(typeof(wpDT));
        // // BLprt && console.log(typeof('wpDT'));
        // BLprt && console.log("+++.");
    },[wpDT]);


    const [modal1Open, setModal1Open] = useState(false);
    const [RTotal, sRTotal] = useState(0);
    const [subTotal, sSubTotal] = useState(0);
    const [genTotal, sGenTotal] = useState(0);
    const [totalLast, sTotalLast] = useState(0);

    const [loading, setLoading] = useState(false);
    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            // setModal1Open(false);
        }, 2000);
    };

    const handleChange = (pagination, filters, sorter, extra) => {
        sRdToRld(false);
        BLprt && console.log('Various parameters', pagination, filters, sorter, extra);
        BLprt && console.log('Various p1', sorter);
        BLprt && console.log('Various p2', sorter.compare);
        BLprt && console.log('Various p3', sorter.multiple);
        BLprt && console.log('Various p4', filters);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
        setSortedInfo_m(sorter);
        BLprt && console.log('Various p5', filteredInfo);
        setTimeout(function(){
            // BLprt && console.log("Pause");
            sRdToRld(true);
        }, 710);
    };

    useEffect(() => {
        BLprt && console.log('Various p5', filteredInfo);
    },[filteredInfo]);

    useEffect(() => {
        if (rdToRld) {
            BLprt && console.log('!!!!!!!!!!___changes +++++');
            onWpBrd2();
            BLprt && console.log('!!!!!!!!!!___changes +++++');
        };
    },[filteredInfo, sortedInfo_n_col3, sortedInfo_n_col6, sortedInfo_n_col9, rdToRld]);

    const onChange1q = (pagination, filters, sorter, extra) => {
        handleChange(pagination, filters, sorter, extra);
        BLprt && console.log('params_1', pagination, filters, sorter, extra);
        BLprt && console.log('pag____', pagination);
        BLprt && console.log('fil____', filters);
        myFilter(filters);
        BLprt && console.log('sor____', sorter);
        BLprt && console.log('x____', extra);


        // setTimeout(function(){
        //     BLprt && console.log("Pause22222");
        //     onWpBrd2();
        // }, 2100);
    };

    const clearFilters = () => {
        setFilteredInfo({});
        // setSortedInfo_n_col3([]);
        // setSortedInfo_n_col6([]);
        // setSortedInfo_n_col9([]);
        myFilter({});
        sArrNul(false);
        BLprt && console.log("clear FIL done, ArrNul  - ", ArrNul);
    };
    const clearAll = () => {
        setFilteredInfo({});
        setSortedInfo({});
        setSortedInfo_m({});
        myFilter({});
        sArrNul(false);
        BLprt && console.log("clear ALL done, ArrNul  - ", ArrNul);
    };

    useEffect(() => {
        BLprt && console.log("useEffect, ArrNul  - ", ArrNul);
        if (ArrNul)
            sTotalLast(subTotal);
        else
            sTotalLast(genTotal);
    }, [ArrNul, subTotal]);

    const myFilter = (filt) => {
        BLprt && console.log("<<<<___FILTER");
        BLprt && console.log("<<<<___");
        BLprt && console.log(filt);
        BLprt && console.log(filt.length);
        BLprt && console.log(!filt.length);
        sArrNul(false);
        var ArrNul12 = false;
        if (Array.isArray(filt.Parm6) && filt.Parm6.length) {
            BLprt && console.log("UUUU ++");
            sArrNul(true);
            ArrNul12 = true;
        } else {
            BLprt && console.log("UUUU --");
        };
        if (Array.isArray(filt.Parm9) && filt.Parm9.length) {
            BLprt && console.log("UUUU ++");
            sArrNul(true);
            ArrNul12 = true;
        } else {
            BLprt && console.log("UUUU --");
        };
        BLprt && console.log("ArrNul   ", ArrNul);
        BLprt && console.log("ArrNul12   ", ArrNul12);
        BLprt && console.log(filt.Parm9);
        BLprt && console.log(filt.Parm6);
        var newDatadata = [];
        var filTrue = false;
        if (filt.Parm6){
            BLprt && console.log(filt.Parm6.length);
            filTrue = true;
            datadata.forEach(eachprtDT => {
                var eBnd = false;
                filt.Parm6.forEach(Afiltr => {
                    // BLprt && console.log("eachprtDT _ ",eachprtDT);
                    // BLprt && console.log("eachprtDT _ ",eachprtDT.ao_fr);
                    if (eachprtDT.ao_fr.includes(Afiltr)){
                        eBnd = true;
                        // BLprt && console.log("6_-____", Afiltr);
                        BLprt && console.log("+.+");
                    };
                });
                if (eBnd){
                    newDatadata.push({ ...eachprtDT });
                };
            });

        };
        BLprt && console.log("$$$____ ", newDatadata);
        BLprt && console.log(filTrue);
        if (filt.Parm9){
            if (filTrue) {
                BLprt && console.log("LOOP start");
                BLprt && console.log("LOOP start");
                var ii2 = newDatadata.length - 1;
                for (let i1 = ii2; i1 >= 0; i1--) {
                    BLprt && console.log("i1  _  ", i1);
                    BLprt && console.log(newDatadata[i1]);
                    BLprt && console.log(newDatadata[i1].id);
                    BLprt && console.log(newDatadata[i1].ao_ph_nmbr);
                };
                BLprt && console.log("LOOP finished");
                BLprt && console.log("LOOP finished");
 
                var eNmb = newDatadata.length - 1;
                for (let iEach1 = eNmb; iEach1>=0; iEach1--) {
                    var eBnd = false;
                    BLprt && console.log("# !_____", iEach1);
                    BLprt && console.log("# !!____", newDatadata[iEach1].id);
                    BLprt && console.log("# !!!___", newDatadata[iEach1].ao_ph_nmbr);
                    filt.Parm9.forEach(Afiltr => {
                        if (newDatadata[iEach1].ao_ph_nmbr.includes(Afiltr)) {
                            eBnd = true;
                            BLprt && console.log("trUETRue");
                            BLprt && console.log("iEach1", iEach1);
                            BLprt && console.log(newDatadata[iEach1]);
                        };
                    });
                    if (!eBnd) {
                        BLprt && console.log("yyyyyyyy");
                        // BLprt && console.log(eachNDD);
                        // BLprt && console.log(eachNDD.id);
                        BLprt && console.log(eBnd);
                        BLprt && console.log(eNmb);
                        BLprt && console.log("iEach1", iEach1);
                        BLprt && console.log(newDatadata[iEach1]);
                        newDatadata[iEach1].ao_two = 0;
                        // newDatadata = newDatadata.splice(iEach1, 1);
                    };
                    BLprt && console.log("====", newDatadata);

                };

                // newDatadata.reverse().forEach(eachNDD =>{
                //     var eBnd = false;
                //     filt.Parm9.forEach(Afiltr => {
                //         if (eachNDD.ao_ph_nmbr.includes(Afiltr)) {
                //             eBnd = true;
                //         };
                //     });
                //     if (!eBnd) {
                //         BLprt && console.log("yyyyyyyy");
                //         BLprt && console.log(eachNDD);
                //         BLprt && console.log(eachNDD.id);
                //         BLprt && console.log(eNmb);
                //         newDatadata = newDatadata.splice(eNmb, 1)
                //     };
                //     eNmb -= 1;
                // });

                BLprt && console.log(filt.Parm9.length);
                filt.Parm9.forEach(Afiltr => {
                    BLprt && console.log("9_-____", Afiltr);
                });
 
            } else {
                BLprt && console.log(filt.Parm9.length);
                datadata.forEach(eachprtDT => {
                    var eBnd = false;
                    filt.Parm9.forEach(Afiltr => {
                        BLprt && console.log("9_-____", Afiltr);
                        if (eachprtDT.ao_ph_nmbr.includes(Afiltr)){
                            eBnd = true;
                            BLprt && console.log("+.+");
                        };
                    });
                    if (eBnd){
                        newDatadata.push(eachprtDT);
                    };
                }); 
            };
        };
        BLprt && console.log("$$$_$$$____ ", newDatadata);
        if (newDatadata.length>0) {
            BLprt && console.log("HURRAY");
        };
        // filt.forEach(Afiltr => {
        //     BLprt && console.log("_-____", Afiltr);
        // });
        BLprt && console.log("___>>>>");
        // var dfMbln = false;
        // datadata.forEach(eachprt => {
        //     // var dfbln = (eachprt.PrtPart_name !== eachprt.PrtPart_name2); тимчасово, при потребі)
        //     var dfP3 = (eachprt.Parm3 !== eachprt.Parm3_1);
        //     var dfP6 = (eachprt.Parm6 !== eachprt.Parm6_1);
        //     var dfbln1 = false;
        //     (dfP3 === true || dfP6 === true) ? dfbln1 = true : dfbln1 = false;
        //     if (dfbln1) {
        //         dfMbln = true;
        //         //         BLprt && console.log(eachprt);
        //         //         BLprt && console.log(eachprt.PrtId);
        //         //         BLprt && console.log(dfbln1);
        //         var dfobj = ({
        //             id:eachprt.key,
        //             ao_one:eachprt.Parm2,
        //             ao_two:eachprt.Parm3,
        //             ao_three:eachprt.Parm5,
        //             ao_fr:eachprt.Parm6,
        //             // ao_sx:eachprt.Parm7,
        //             // ao_ph_nmbr:eachprt.Parm9,
        //             // ao_email:eachprt.Parm10,
        //         });
        //         BLprt && console.log('_________________________dfobj');
        //         BLprt && console.log(dfobj);
        //         // axios.put(`http://127.0.0.1:7801/api/board/wpsecpart/${eachprt.Parm1}/`, dfobj)
        //         // axios.put(`http://127.0.0.1:8000/api/aapp/app_one/${eachprt.Parm1}/`, dfobj)
        //         // axios.put(`http://localhost:8000/api/aapp/app_one/${eachprt.Parm1}/`, dfobj)
        //         // axiosInstance.put(`/aapp/app_one/${eachprt.Parm1}/`, dfobj)
        //         axiosInstance.put(`/aapp/app_one/${eachprt.Parm1}/`+'?dtqw='+(new Date()).getTime(), dfobj)
        //             .then(res => BLprt && console.log(res.data))
        //             .catch(err => BLprt && console.log(err))
        //         };
        //     });
        //     // BLprt && console.log("==_______ &  " + new Date().toLocaleDateString());
        //     // setTimeout(function(){
        //     //     BLprt && console.log("Pause");
        //     //     if (dfMbln){ {onWpBrd()}};
        //     // }, 2100);
        //     // BLprt && console.log("++_______ &  " + new Date().toLocaleDateString())
        var tmp2 = 0;
        newDatadata.forEach(eachNDD2 => {
            tmp2 = tmp2 + eachNDD2.ao_two;
        });
        sSubTotal(tmp2);
        // sSubTotal
        newDatadata = "gfg_qq";
        BLprt && console.log("newDatadata___ = ", newDatadata);
        BLprt && console.log("datadata___ = ", datadata);
        BLprt && console.log("FILTER___>>>>");
        // sOnowBRD2(!onowBRD2);
    };

    useEffect(() =>{
        BLprt && console.log("TYTYTYTYTYTYTYTYTYT");
        onWpBrd2();
    },[onowBRD2]);


    const [top, setTop] = useState('topLeft');
    const [bottom, setBottom] = useState('bottomRight');


    return(
        <>
            <br></br>
            <Button
                onClick={onWpBrd}>
                O N E
            </Button>
            <Button
                onClick={onWpBrd2}>
                O N E 2
            </Button>
            <Button
                onClick={saveToDtbs}
                type="primary"
                disabled={btndisablecount}
                style={{
                    marginBottom: 16,
                    marginLeft: 16,
                    position: 'absolute',
                    zIndex: '3',
                    top: '65px',
                    left: '200px',
                }}
            >
                Save==
            </Button>
            <Button
                onClick={() => setModal1Open(true)}
                type="primary"
                shape="circle"
                // disabled={btndisablecount}
                icon={<PlusCircleOutlined />}
                size={'large'}
                style={{
                    // marginBottom: 16,
                    // marginLeft: 16,
                    position: 'absolute',
                    zIndex: '3',
                    top: '125px',
                    left: '300px',
                }}
            >
            </Button>
            <Modal
                title="New part"
                style={{
                    top: 30,
                }}
                open={modal1Open}
                onOk={() => {
                    // handleSubmit();
                    BLprt && console.log("!@")
                    formRef.current.dispatchEvent(
                        new Event('submit', { cancelable: true, bubbles: true })
                    );
                    BLprt && console.log("!@");
                    handleOk();
                    BLprt && console.log("++");
                }
                }
                // onCancel={setModal1Open(false)}
                // footer={[
                //   <Button key="back" onClick={setModal1Open(false)}>
                //     Cancel
                //   </Button>,
                // //   <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                // //     Submit
                // //   </Button>,
                // //   <Button
                // //     key="link"
                // //     href="https://google.com"
                // //     type="primary"
                // //     loading={loading}
                // //     onClick={handleOk}
                // //   >
                // //     Search on Google
                // //   </Button>,
                // ]}
                // onOk={() =>
                //     {
                //         return new Promise((resolve, reject) => {
                //             setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                //         }).catch(() => BLprt && console.log('Oops errors!'));
                //     }
                // }
                // onOk() {
                //     return new Promise((resolve, reject) => {
                //         setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                //     }).catch(() => BLprt && console.log('Oops errors!'));
                // },
                onCancel={() => setModal1Open(false)}
            >
                {/* <p>some contents...</p>
                <p>some contents...</p>
                <p>some contents...</p> */}

                <div className="example">
                    <h5>Example</h5>
                    <FloatLabel label="First Name" name="firstName1" value={firstName}>
                        <Input value={firstName} onChange={e => setFirstName(e.target.value)}/>
                    </FloatLabel>
                    {/* <FloatLabel label="Last Name" name="lastName1" value={lastName}>
                        <Input value={lastName} onChange={e => setLastName(e.target.value)}/>
                    </FloatLabel> */}
                    {/* <FloatLabel label="Tree Select" name="lastName" value={treeValue}>
                        <TreeSelect {...tProps} />
                    </FloatLabel> */}
                    {/* <FloatLabel label="Select Option" name="name" value={selectValue}>
                        <Select
                        showSearch
                        style={{ width: "100%" }}
                        onChange={value => setSelectValue(value)}
                        value={selectValue}
                        mode="tags"
                        >
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                        <Option value="tom">Tom</Option>
                        <Option value="jerry">Jerry</Option>
                        </Select>
                    </FloatLabel> */}
                    <h5>Example</h5>
                </div>

                <form style={{justifyContent: "start" }} onSubmit={handleSubmit} ref={formRef}>
                    {/* <div> */}
                    {/* <input type="text" ref={inputRefMod} />
                    <input type="text" ref={inputRefMod2} /> */}
                    <Input type="text" placeholder="Parm2, name" value={inAo_one} onChange={e => sAo_one(e.target.value)}/>
                    <Input type="number" max={500} min={0} placeholder="Parm3, amount" value={inAo_two} onChange={e => sAo_two(e.target.value)}/>
                    <Input type="text" placeholder="Parm5" value={inAo_three} onChange={e => sAo_three(e.target.value)}/>
                    <Input type="text" placeholder="Parm6" value={inAo_fr} onChange={e => sAo_fr(e.target.value)}/>
                    <Checkbox checked={inAo_sx} disabled={disabled} onChange={onChange} style={{color:"gray"}}>
                        {label}
                    </Checkbox>
                    <Input type="text" placeholder="Parm9, +380991234567" value={inAo_ph_nmbr} onChange={e => sAo_ph_nmbr(e.target.value)}/>
                    <Input type="text" placeholder="Parm10, e-mail:name@domen.com" value={inAo_email} onChange={e => sAo_email(e.target.value)}/>
                    {/* <button type="submit">Submit</button> */}
                    <label style={{color:"red", marginLeft:"15px"}}>{inAlert}</label>
                    {/* </div> */}
                </form>

                {/* <button onClick={() => formRef.current.dispatchEvent(
                    new Event('submit', { cancelable: true, bubbles: true })
                )}>
                    Submit2
                </button> */}
                {/* <Input style={{width:'80%', color:'green'}} ref={inputRefMod} onPressEnter={save} onBlur={save} /> */}
                {/* object.id, Parm1: object.id, Parm2: object.ao_one, Parm3: object.ao_two, Parm3_1: object.ao_two, Parm3_2: object.ao_qty, Parm3_diff:false, Parm4: object.ao_dtrt, Parm5: object.ao_three, Parm6: object.ao_fr, Parm6_1: object.ao_fr_1, Parm6_2: object.ao_fr_2, Parm6_diff:false, Parm7: object.ao_sx, Parm8: object.phone_regex, Parm9: object.ao_ph_nmbr, Parm10: object.ao_email */}
            </Modal>
            <Button
                onClick={cnclAll}
                type="primary"
                disabled={btndisablecount}
                style={{
                    marginBottom: 16,
                    marginLeft: 16,
                    position: 'absolute',
                    zIndex: '3',
                    top: '65px',
                    left: '300px',
                }}
            >
                Cancel--
            </Button>
            <div>
                <span>__________________________</span>
                {/* {upList.map((upList, i) => <p key={i}>{upList}</p>)} */}
                <span>__get ID & 2 before + 2 after________________________</span>
                {/* {upList.map((upList, i) => <p key={i}>{upList.pk} -_- {upList.fields.ao_one} -_- {upList.fields.ao_two} -_- {upList.fields.ao_three} -_- {upList.fields.ao_fr} -_- {upList.fields.ao_sx} -_- {upList.fields.ao_email} -_- {upList.fields.ao_ph_nmbr} -_- {upList.fields.ao_dtrt}</p>)} */}
                <br></br>
                <span>__________________________</span>
                <span>__sort & filter from back________________________</span>
                {wpDsf.map((wpDsf, i) => <p key={i}>pk_{wpDsf.id} -_   - {wpDsf.ao_one} -_   - ao_two_{wpDsf.ao_two} -_   - ao_three_{wpDsf.ao_three} -_   - ao_ph_nmbr_{wpDsf.ao_ph_nmbr} -_   - ao_fr_{wpDsf.ao_fr}</p>)}
                <span>__________________________</span>
            </div>
            <Space
                style={{
                marginBottom: 16,
                }}
            >
                {/* <Button onClick={setSort}>Sort</Button> */}
                <Button onClick={clearFilters}>Clear filters</Button>
                <Button onClick={clearAll}>Clear filters and sorters</Button>
            </Space>
            <span>   FiltTotal {subTotal}</span>, _ <span>Total {genTotal}</span>, _ <span>TotalLAST {totalLast}</span>
            <Table
                dataSource={datadata}
                columns={columnsS}
                rowClassName={(record, index) => (record.Parm7 ? "red" : "green")}
                components={components}
                bordered
                style={{backgroundColor:"#636465a1"}}
                pagination={{
                    defaultPageSize: 18,
                    showSizeChanger: true,
                    position: [bottom, top],
                    // position: ["bottomRight", "topRight"],
                    size:"small",
                    locale: { items_per_page: '' },
                    // style: { marginTop: '18px', border: '1px solid #cdc1ff', borderRadius: '6px', padding: '4px' },
                    pageSizeOptions: ['1', '2', '10', '20', '30', '101'],
                }}
                // ііі тут помилка лише при відкритті сторінки
                // scroll={{ x: 1100 }}
                // scroll={{ x: 1100, y: 55*5 }}
                onChange={onChange1q}
            />
            <div>
                {wpDT.map((wpDT, i) => <p key={i}>{wpDT.id} - {wpDT.ao_one} - {wpDT.ao_two} - {wpDT.ao_dtrt} - {wpDT.ao_three} - {wpDT.ao_fr} - {wpDT.phone_regex} - {wpDT.ao_ph_nmbr} - {wpDT.ao_email}</p>)}
                <br></br>
                <span>__________________________</span>
                {wpDT.map((wpDT, i) => <p key={i}>{wpDT.id} - {wpDT.ao_one} - {wpDT.ao_two} - {wpDT.wp_project} - {wpDT.prjct}</p>)}
                {/* {wpDT.map((wpDT, i) => <p key={i} style={{paddingLeft:'22px'}}>{   }{wpDT.id} - {wpDT.wp_name} - {wpDT.boards_amount} - {wpDT.wp_project} - {wpDT.prjct}</p>)} */}
            </div>
        </>
    );
};

export default ApOne;
