import React from "react";
import { useState, useEffect, useRef, useContext, newState } from "react";
import { Button, Dropdown, Table, Form, Input, Modal, Select, Space, TreeSelect, Checkbox, CheckboxProps, Affix } from "antd";
import axios from "axios";
import axiosInstance from "../AxiosApi";
import { CheckOutlined, CloseOutlined, PlusCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import "./css/wrd.css";
import { Link, Routes, Route } from "react-router-dom";


function Drpdwn_Links () {

    const[btnnm, sBtnnm] = useState('Main');

    const items = [
        {
            key: '1',
            label: (
                // <a target="_blank" rel1="noopener noreferrer" href="/dct/wrd_a">
                // <a target="_blank" href="/dct/wrd_a">
                <Link to="/dct/wrd_a">
                    1st
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                // <a href="/dct/wrd_b">
                // {/* <Route path='/dct/wrd_b' element={<ApOne/>} /> */}
                //     2nd
                // </a>
                <Link to="/dct/wrd_b">
                    2nd
                </Link>
            ),
        },
        {
            key: '3',
            label: (
                // <a href="/dct/wrd_c">
                //     3rd
                // </a>
                <Link to="/dct/wrd_c">
                    3st
                </Link>
            ),
        },
    ];

    return (
        <>
            <Dropdown
                menu={{
                    items,
                }}
                placement="bottomLeft"
            >
                <Button>bottomRight</Button>
            </Dropdown>
        </>
    );
};


export default Drpdwn_Links;