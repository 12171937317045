import axios from "axios"

// server&
// const baseURL = "http://127.0.0.1:8000/api/"
const baseURL = "https://allisfun.xyz/api/"

// const baseURL = process.env.REACT_APP_API_URL

// const baseURL = "127.0.0.1:8000/api/"
// const baseURL = "127.0.0.1/api/"
// const baseURL = "/api/"

// axios.defaults.baseURL = 'http://localhost';

const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 5000,
    headers: {
        Authorization: localStorage.getItem("access_token")
            ? `Bearer ${localStorage.getItem("access_token")}`
            : null,
        "Content-Type": "application/json",
        accept: "application/json"
    }
})

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        const originalRequest = error.config

        // Prevent infinite loops early
        if (
            error.response.status === 401 &&
            originalRequest.url === baseURL + "token/refresh/"
        ) {
            // console.log("TOKEN    ___    111")
            window.location.href = "/login/"
            return Promise.reject(error)
        }
        
        if (
            error.response.data.code === "token_not_valid" &&
            error.response.status === 401 &&
            error.response.statusText === "Unauthorized"
        ) {
            const refreshToken = localStorage.getItem("refresh_token")
            // console.log("TOKEN    ___    222")
            
            if (refreshToken) {
                const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]))

                // exp date in token is expressed in seconds, while now() returns milliseconds:
                const now = Math.ceil(Date.now() / 1000)
                console.log(tokenParts.exp)
                // console.log("TOKEN    ___    333")
                
                if (tokenParts.exp > now) {
                    // console.log("TOKEN    ___    444")
                    return axiosInstance
                    .post("/token/refresh/", { refresh: refreshToken })
                    .then((response) => {
                        localStorage.setItem(
                                "access_token",
                                response.data.access
                            )
                            localStorage.setItem(
                                "refresh_token",
                                response.data.refresh
                            )
                            const access_token = response.data.access
                            axiosInstance.defaults.headers[
                                "Authorization"
                            ] = `Bearer ${access_token}`
                            originalRequest.headers[
                                "Authorization"
                            ] = `Bearer ${access_token}`

                            return axiosInstance(originalRequest)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                } else {
                    // console.log("TOKEN    ___    555")
                    console.log("Refresh token is expired", tokenParts.exp, now)
                    window.location.href = "/login/"
                }
            } else {
                // console.log("TOKEN    ___    666")
                console.log("Refresh token not available.")
                window.location.href = "/login/"
            }
        }

        // specific error handling done elsewhere
        return Promise.reject(error)
    }
)

export default axiosInstance
