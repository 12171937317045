// import React, { useEffect, useState } from "react";
import React, { useState } from "react";
import { Button } from "antd";
import axios from "axios";

// const baseURL_2 = process.env.REACT_APP_API_URL

function ApTwo () {

    const BLprt = 1;

    const [wpDT, setWpdt] = useState([]);

    const onWpBrd = () => {

        BLprt && console.log("+");
        // server&
        // axios.get('http://127.0.0.1:8000/api/aapp/app_two/')
        axios.get('https://allisfun/api/aapp/app_two/')
        // axios.get(baseURL_2 +'aapp/app_two/')
        .then(res => {
            BLprt && console.log("++++");
            BLprt && console.log(res.data);
            BLprt && console.log(res.data.results);
            setWpdt(res.data);
        })
        .catch(err => BLprt && console.log("err"));
    };

    return(
        <>
            <Button onClick={onWpBrd}>T W O</Button>
            <div>
                {wpDT.map((wpDT, i) => <p key={i}>{wpDT.id} - {wpDT.at_one} - {wpDT.at_ao_one} - {wpDT.at_about} - {wpDT.at_two} - {wpDT.at_three} - {wpDT.at_4_strt} - {wpDT.at_5_done}</p>)}
                {/* {wpDT.map((wpDT, i) => <p key={i} style={{paddingLeft:'22px'}}>{   }{wpDT.id} - {wpDT.wp_name} - {wpDT.boards_amount} - {wpDT.wp_project} - {wpDT.prjct}</p>)} */}
            </div>
        </>
    );
};

export default ApTwo;