import React from "react";
import { useState, useEffect, useRef, useContext, newState } from "react";
import { Button, Dropdown, Table, Form, Input, Modal, Select, Space, TreeSelect, Checkbox, CheckboxProps, Affix } from "antd";
import axios from "axios";
import axiosInstance from "../AxiosApi";
import { CheckOutlined, CloseOutlined, PlusCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import "./css/wrd.css";
import { Link, Routes, Route } from "react-router-dom";
import Drpdwn_Links from "./links_w";


function Wrd_b () {
	
	const BLprt = 1;

    const[wrd1, sWrd1] = useState([]);
    const[mdlOpenWrd1, sMdlOpenWrd1] = useState(false);
    const[userN, suserN] = useState("pdffesx");
    const formRef = React.useRef(null);
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState("Nikhil.wrdB");
    const [inAlert, sInAlert] = useState("");
    const [inWRD_one, sInWRD_one] = useState("c1");
    const [inWRD_two, sInWRD_two] = useState("TRcdc");
    const [inWRD_thr, sInWRD_thr] = useState("wrFEX");
    let inpArray = [inWRD_one, inWRD_two, inWRD_thr];

    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            // sMdlOpenWrd1(false);
        }, 2000);
    };

    function handleSubmit(event) {
        event.preventDefault();
        sInAlert("");
        if (inpArray.includes("")) {
            BLprt && console.log("Input is empty");
            sInAlert("Fill all fields");
        } else {
            BLprt && console.log("Input is not empty");
            createItem();
        }
    };

    const createItem  = async (event) => {
        // event.preventDefault();
        BLprt && console.log("+");
        BLprt && console.log(inWRD_one, inWRD_two, inWRD_thr, "hhh");
        try {
            BLprt && console.log("#$#$%#$%##");
            const response = await axiosInstance.post("dctn/wrd_c/", {
                aoWR_one: inWRD_one,
                user_create: "uusr1",
                aoWR_two: inWRD_two,
                aoWR_thr: inWRD_thr
            });
            BLprt && console.log("+++==+++");   //123123q!  --  password
            // POTIM ROZKOMENTUWATY
            // // sInWRD_one("");
            // // sInWRD_two("");
            // // sInWRD_thr("");;
            // // sInAlert("");
            // // sMdlOpenWrd1(false);
            // POTIM ROZKOMENTUWATY
        } catch (error) {
            BLprt && console.log("ERRRRRR");
            BLprt && console.log(error);
            BLprt && console.log("__1");
            BLprt && console.log(error.response.data);
            BLprt && console.log("__2");
            const episodes = error.response.data;
            BLprt && console.log(episodes[0]);
            BLprt && console.log(episodes.type);
            BLprt && console.log(error.response.data.type);
            BLprt && console.log("__2_!");
            BLprt && console.log(Object.prototype.toString.call(error.response.data));
            BLprt && console.log(typeof(error.response.data));
            if (error.response.data) {
                for (const [key, value] of Object.entries(error.response.data)) {
                    BLprt && console.log(`${key}: ${value}`);
                    for (const [key1, value1] of Object.entries(value)) {
                        BLprt && console.log(`${key1}: ${value1}`);
                    }
                }
            }
            BLprt && console.log("__2_@");
            var secondKey = Object.keys(error.response.data)[0];
            BLprt && console.log(error.response.data[secondKey]);
            var thirdKey = Object.keys(error.response.data[secondKey])[0];
            BLprt && console.log(error.response.data[secondKey][thirdKey]);
            var fourthdKey = Object.keys(error.response.data[secondKey])[error.response.data[secondKey].length - 1];
            BLprt && console.log(error.response.data[secondKey].length);
            BLprt && console.log(error.response.data[secondKey][fourthdKey]);
            sInAlert(error.response.data[secondKey][fourthdKey]);

            // const result = {status: 'Success', length: 7, data: {…}};
            // episodes.map(function(item, i){
            //     BLprt && console.log(item);
            // })
            BLprt && console.log("__3");
            BLprt && console.log(error.response.status);
            BLprt && console.log("ERRRRRR");
            // throw(error);
        };
    };

    const onWrd_b = () => {
        axiosInstance.get("/dctn/wrd_c/")
        // axiosInstance.get("/dctn/wrd_c/"+'?dtqw='+(new Date()).getTime()+'&qId=5')
        .then(res => {
            BLprt && console.log("++++ WRD WRD wrd_B");
            BLprt && console.log(res.data.results);
            BLprt && console.log("====================");
            BLprt && console.log(res.data[0]);
            BLprt && console.log("====================");
            sWrd1(res.data);
        })
        .catch(err => BLprt && console.log("err"));
    };

    useEffect(()=> {
        BLprt && console.log('console.log(wrd1);');
        BLprt && console.log(wrd1);
        BLprt && console.log('console.log(wrd1);');
    }, [wrd1]);

    const FloatLabel = props => {
        const [focus, setFocus] = useState(false);
        const { children, label, value } = props;

        const labelClass =
            focus || (value && value.length !== 0) ? "label label-float" : "label";

        return (
            <div
                className="float-label"
                onBlur={() => (setFocus(false), BLprt && console.log("Fb"))}
                onFocus={() => (setFocus(true), BLprt && console.log("Ff"))}
            >
                {children}
                <label className={labelClass}>{label}</label>
            </div>
        );
    };

    const items = [
        {
            key: '1',
            label: (
                // <a target="_blank" rel1="noopener noreferrer" href="/dct/wrd_a">
                // <a target="_blank" href="/dct/wrd_a">
                <Link to="/dct/wrd_a">
                    1st menu item
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                // <a href="/dct/wrd_b">
                // {/* <Route path='/dct/wrd_b' element={<ApOne/>} /> */}
                //     2nd menu item
                // </a>
                <Link to="/dct/wrd_b">
                    2nd menu item
                </Link>
            ),
        },
        {
            key: '3',
            label: (
                // <a href="/dct/wrd_c">
                //     3rd menu item
                // </a>
                <Link to="/dct/wrd_c">
                    3st menu item
                </Link>
            ),
        },
    ];

    return (
        <>
            <Modal
                title="Add new word"
                style={{
                    top: 20,
                    right: 14,
                }}
                open={mdlOpenWrd1}
                onOk={() => {
                    // handleSubmit();
                    BLprt && console.log("!@")
                    formRef.current.dispatchEvent(
                        new Event('submit', { cancelable: true, bubbles: true })
                    );
                    BLprt && console.log("!@");
                    handleOk();
                    BLprt && console.log("++");
                }}
                onCancel={() => sMdlOpenWrd1(false)}
            >
                <div className="example">
                    <h5>ExampleWR</h5>
                    <FloatLabel label="First Name" name="firstName1" value={firstName}>
                        <Input value={firstName} onChange={e => setFirstName(e.target.value)}/>
                    </FloatLabel>
                    <h5>ExampleWR</h5>
                </div>

                <form style={{justifyContent: "start" }} onSubmit={handleSubmit} ref={formRef}>
                    {/* <div> */}
                    {/* <input type="text" ref={inputRefMod} />
                    <input type="text" ref={inputRefMod2} /> */}
                    <Input type="text" placeholder="Word_1" value={inWRD_one} onChange={e => sInWRD_one(e.target.value)}/>
                    <Input type="text" placeholder="WordTR" value={inWRD_two} onChange={e => sInWRD_two(e.target.value)}/>
                    <Input type="text" placeholder="Word_2" value={inWRD_thr} onChange={e => sInWRD_thr(e.target.value)}/>
                    {/* <button type="submit">Submit</button> */}
                    <label style={{color:"red", marginLeft:"15px"}}>{inAlert}</label>
                    {/* </div> */}
                </form>
            </Modal>
            <br></br>
            <span className="ffr">wrf__yjyt{'<<'}</span>
            <p>{userN}</p>
            <Dropdown
                menu={{
                    items,
                }}
                placement="bottomLeft"
            >
                <Button>bottomLeft</Button>
            </Dropdown>
            <Drpdwn_Links/>
            <Button
                // onClick={onWrd}>
                onClick={BLprt && console.log("onWrd")}>
                    Onowl
            </Button>
            <Button
                // onClick={onWrd_a}>
                onClick={BLprt && console.log("onWrd_a")}>
                    Onowl_A
            </Button>
            <Button
                onClick={onWrd_b}>
                    Onowl_B
            </Button>
            <Button
                onClick={() => (sMdlOpenWrd1(true), BLprt && console.log(9908))}
                type="primary"
                shape="circle"
                // disabled={btndisablecount}
                icon={<PlusCircleOutlined />}
                size={'large'}
                style={{
                    // marginBottom: 16,
                    // marginLeft: 16,
                    position: 'absolute',
                    zIndex: '3',
                    top: '125px',
                    left: '300px',
                }}
            >
            </Button>
            <span>wrf__yjyt{'>>'}</span>
            <p>B_B_B_B_B_B</p>
            {wrd1.map((wrd1, i) => <p key={i}>{wrd1.id} - {wrd1.wc_text} - {wrd1.wc_trans} - {wrd1.user_create} - {wrd1.date_created}</p>)}
        </>
    );
};


export default Wrd_b;