import React, { useEffect, useState } from "react";
import { Button, Input, Select, Modal } from "antd";
import axiosInstance from "../../AxiosApi";

function Inpt_Dct ({yy, uyu=1, sndData}) {
	
	const BLprt = 1;

    const[valueVS, sValueVS] = useState('_');
    const[count_get, sCount_get] = useState(0);
    const[count_gt_2, sCount_gt_2] = useState(0);
    const[val_len, sVal_len] = useState(0);
    const[wrd_get, sWrd_get] = useState([]);
    const[wrd_rew_2, sWrd_rew_2] = useState([]);
    const[wrd_rework, sWrd_rework] = useState([]);
    const[wrd_list, sWrd_list] = useState([
        { id: `id1`, name: 'channel1', w_text: 'w_txt_1'},
        { id: `id2`, name: 'channel2', w_text: 'w_txt_2'},
    ]);
    const[wrd_lt_2, sWrd_lt_2] = useState([]);
    const[filteredArrDct, sFilteredArrDct] = useState([]);

    const [b_tr, sB_tr] = useState(false);
    const [b_rew, sB_rew] = useState(false);




    const onWrdAA = (t_len = "ooOO", txt_str = 1) => {
        // axiosInstance.get("/dctn/wrd_a/")
        BLprt && console.log(t_len);
        BLprt && console.log("t_len");
        BLprt && console.log(txt_str);
        var valVS = "";
        if (t_len != 0) {
            valVS = txt_str;
        } else {
            valVS = valueVS;
        };
        BLprt && console.log("<<<<<<");
        BLprt && console.log(t_len);
        BLprt && console.log(txt_str);
        BLprt && console.log(valVS);
        BLprt && console.log(valueVS);
        axiosInstance.get("/dctn/wrd_a/"+'?rkop='+t_len+'&rktxt='+valVS)
        // axiosInstance.get("/dctn/wrd_a/"+'?dtqw='+(new Date()).getTime()+'&qId=5')
        .then(res => {
            BLprt && console.log("++++ onWrdAA");
            // // BLprt && console.log("++++ WRD WRDA trnslt");
            // // BLprt && console.log(res.data.results);
            // // BLprt && console.log("====================");
            // // BLprt && console.log(res.data);
            // // BLprt && console.log(res.data[0]);
            sWrd_get(res.data[0]);
        })
        .catch(err => BLprt && console.log("err"));
    };

    useEffect(() => {
        setTimeout(() => {
            BLprt && console.log();
            onWrdAA(count_get, "");
            sCount_get(count_get + 1);
        }, 2000);
    }, []);

    useEffect(() => {
        // BLprt && console.log("&&&&&&&&&===========");
        if (b_tr) {
            BLprt && console.log();
            if (count_gt_2 == 0) {
                sWrd_rew_2(wrd_get.map(object => {return {...object, key: object.id, P_id: object.id, P_w_text: object.w_text, P_w_trans: object.w_trans, P_user_create: object.user_create, P_date_created: object.date_created, Parm3_diff:false, diffQtyMx:false, value: object.id, label: object.w_text}}));
                sCount_gt_2(count_gt_2 + 1);
            } else {
                BLprt && console.log(11111111111111111111111111111111111111111);
                sWrd_rework(wrd_get.map(object => {return {...object, key: object.id, P_id: object.id, P_w_text: object.w_text, P_w_trans: object.w_trans, P_user_create: object.user_create, P_date_created: object.date_created, Parm3_diff:false, diffQtyMx:false, value: object.id, label: object.w_text}}));
            };
        };
    },[wrd_get]);

    useEffect(() => {
        if (count_gt_2 == 1) {
            sWrd_list(wrd_rew_2);
        };
    },[wrd_rew_2]);

    useEffect(() => {
        if (b_tr) {
            BLprt && console.log();
            BLprt && console.log("filteredArrDct.length___");
            BLprt && console.log(filteredArrDct);
            BLprt && console.log(filteredArrDct.length);
            if (filteredArrDct.length < 4 ) {
                onWrdAA(3, valueVS);
                BLprt && console.log();
                // onWrdAA("LllLLl");
            };
        };
    },[filteredArrDct]);

    const handleChangeVS = (newValue) => {
        BLprt && console.log("child__newValue  ", newValue);
        sndData(newValue);
        if (typeof newValue === "undefined") {
            // BLprt && console.log("child__undfnd");
            sValueVS('');
        } else {
            // BLprt && console.log("child__ok new");
            sValueVS(newValue);
        };
        // BLprt && console.log("child__#1#1#1#1#1#1#");
    };

    const handleSearchVS = (newValue) => {
        // fetch(newValue, setData);
        sValueVS(newValue);
        BLprt && console.log("child__$2$2$2$2$2$2$");
    };

    useEffect(() => {
        if (valueVS.length < val_len) {
            sB_rew(false);
        };
        sVal_len(valueVS.length);
        if (b_tr) {
            BLprt && console.log("");
            // BLprt && console.log("__==__valueVS__==__");
            // BLprt && console.log(valueVS);
            // BLprt && console.log(valueVS.length);
            if (valueVS.length == 0) {
                // BLprt && console.log("00000000");
                sWrd_list(wrd_rew_2);
                sB_rew(false);
                BLprt && console.log();
            } else {
 
                if (valueVS.length > 0 & valueVS.length < 3) {
                    BLprt && console.log();
                    // let gg = filterArrayByParamAndValue(wrd001AAVS, "w_text", valueVS)
                    // sFilteredArrDct(wrd001AAVS.filter(item => item.w_text.includes(valueVS)));
                    // sFilteredArrDct(wrd_rework.filter((item) => item.w_text.includes(valueVS)));
                    // sFilteredArrDct(wrd_rew_2.filter((item) => item.w_text.includes(valueVS)));
                    sFilteredArrDct(wrd_list.filter((item) => item.w_text.includes(valueVS)));
                    // BLprt && console.log("11111111<<<<<");
                    // BLprt && console.log(filteredArrDct);
                    // BLprt && console.log(wrd001AAVS);
                    // BLprt && console.log(wrd_rework);
                    // sWrd_list(wrd_rework);
                    // BLprt && console.log("11111111");
                } else if (valueVS.length > 2) {
                    BLprt && console.log();
                    if (!b_rew) {
                        onWrdAA(3, valueVS);
                        BLprt && console.log();
                        // sWrd_list(wrd_rework);
                        // BLprt && console.log("88888888");
                    };
                };

            }


        };
    },[valueVS]);


    useEffect(() => {
        BLprt && console.log(b_rew);
        if (b_tr) {
            if (!b_rew) {
                BLprt && console.log(b_rew);
                sB_rew(true);
                sWrd_list(wrd_rework);
            };
        };
    },[wrd_rework]);



    useEffect(() => {
        setTimeout(() => {
            sB_tr(true);
        }, 1000);
    }, [b_tr]);

    return (

        <>
            <Select
                showSearch
                value={valueVS}
                placeholder="please input rrr"
                style={{ width: 200, }}
                defaultActiveFirstOption={false}
                allowClear
                suffixIcon={null}
                // filterOption={false}
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                onSearch={handleSearchVS}
                onChange={handleChangeVS}
                notFoundContent={null}
                // options={(wrd_list || []).map((d) => ({
                options={wrd_list.map((d) => ({
                    value: (d.w_text),
                    label: (d.w_text),
                }))}
            />
        </>
    );
};

export default Inpt_Dct;