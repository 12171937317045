import React from "react";

import CssCls from "./css_class_1";

function LayCss () {
    return (
        <>
            <br></br>
            <CssCls></CssCls>
            <br></br>
        </>
    );
};

export default LayCss;