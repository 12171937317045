import React, { useState } from "react";

import "./css/floatlabel.css";

const FloatLabel22 = props => {
  const [focus, setFocus] = useState(false);
  const { children, label, value } = props;

  const labelClass =
    focus || (value && value.length !== 0) ? "label label-float" : "label";

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label className={labelClass}>{label}</label>
    </div>
  );
};

const FloatLabel = props => {
  const [focus, setFocus] = useState(false);
  const { children, label, value } = props;

  const labelClass =
    focus || (value && value.length !== 0) ? "label label-float" : "label";
    const lblColor =
        focus || (value && value.length !== 0) ? "gray" : "black";

    return (
        <div
            className="float-label"
            //   onBlur={() => setFocus(false)}
            //   onFocus={() => setFocus(true)}
            onBlur={() => (setFocus(false), console.log("Fb"))}
            onFocus={() => (setFocus(true), console.log("Ff"))}
        >
            {children}
            <label style={{ color:lblColor}} className={labelClass}>{label}</label>
        </div>
    );
};

export default FloatLabel;
